import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer_component";
export const Layout_component = () => {
  return (
    <>
      <header className="site-header sticky-top py-1">
        <nav className="container d-flex flex-column flex-md-row justify-content-between">
          <base href="你的基本URL" />

          <a className="py-2" href="/" aria-label="Product">
            <img
              src="/images/logo.svg"
              width="20"
              height="20"
              alt="Product Logo"
            />
          </a>

          <svg
            width="20"
            height="20"
            role="img"
            aria-label="Product"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <title>Product</title>
            <path d="M14.31 8l5.74 9.94M9.69 8h11.48M7.38 12l5.74-9.94M9.69 16L3.95 6.06M14.31 16H2.83m13.79-4l-5.74 9.94"></path>
          </svg>
          {/* 导航链接  */}
          <a className="py-2 d-none d-md-inline-block" href="/">
            首页
          </a>
          <a className="py-2 d-none d-md-inline-block" href="/write">
            撰写
          </a>
          <a className="py-2 d-none d-md-inline-block" href="/check">
            自检
          </a>
          <a className="py-2 d-none d-md-inline-block" href="/aiwrite">
            AI辅助撰写
          </a>
          <a className="py-2 d-none d-md-inline-block" href="/searchnav">
            专利检索网站导航
          </a>
          <a className="py-2 d-none d-md-inline-block" href="/ainav">
            人工智能网站导航
          </a>
        </nav>
      </header>{" "}
      <Outlet />
      <Footer />
    </>
  );
};
export default Layout_component;
