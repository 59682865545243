import React from "react";
import { ReactTyped } from "react-typed";

const WriteTypeWriterDeleteFutuBiaoJi = () => (
  <ReactTyped
    strings={["删除附图标记"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default WriteTypeWriterDeleteFutuBiaoJi;
