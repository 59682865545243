import axios from "axios";

class CheckService {
  // 高亮敏感字符
  async HeightLightCharService(needheightlightminganzifu) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/check/heightlightminganzifu",
        {
          needheightlightminganzifu,
        }
      );
      return response.data;
    } catch (error) {
      console.error("高亮敏感字符失败", error);
    }
  }
  // 高亮所述
  async HeightLightSuoShuService(needHeightLightdata) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/check/heightlightsuoshu",
        {
          needHeightLightdata,
        }
      );
      return response.data;
    } catch (error) {
      console.error("高亮所述失败", error);
    }
  }
  // 取消高亮
  async NohighlightService(cacelHeightLigthtData) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/check/needCacelData",
        {
          cacelHeightLigthtData,
        }
      );
      return response.data;
    } catch (error) {
      console.error("取消高亮失败", error);
    }
  }
}

export default new CheckService();
