import axios from "axios";
class AiWriteService {
  // AI智能回答
  async AiChatService(needaiChat) {
    try {
      let response = await axios.post("https://www.techexplore.cn/aiwrite/", {
        needaiChat,
      });
      return response.data.result;
    } catch (error) {
      console.err("AI提问失败", error);
    }
  }
  // AI检查质量
  async AiCheckService(needcheckdata) {
    try {
      let response = await axios.post("https://www.techexplore.cn/aiwrite/aicheck", {
        needcheckdata,
      });
      return response.data.result;
    } catch (error) {
      console.err("AI检查质量失败", error);
    }
  }
  // AI专利翻译
  async AiTranslateService(needTranslate) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/aitranslate",
        {
          needTranslate,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI专利翻译失败", error);
    }
  }
  // AI技术效果
  async AiTechEffectService(needwritingTechnicalEffects) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/writingTechnicalEffects",
        {
          needwritingTechnicalEffects,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI技术效果失败", error);
    }
  }
  // AI背景技术
  async AiBackGroundService(needwritingBackground) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/writingBackground",
        {
          needwritingBackground,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI背景技术失败", error);
    }
  }
  //AI技术交底
  async AiTechBottomService(needtechnicalDisclosureLetter) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/technicalDisclosureLetter",
        {
          needtechnicalDisclosureLetter,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI技术交底失败", error);
    }
  }
  // AI专利挖掘
  async AipatentMiningService(needPatentMining) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/patentMining",
        {
          needPatentMining,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI专利挖掘失败", error);
    }
  }
  // AI区别特征
  async findDifferenceService(needfindDifference) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/findDifference",
        {
          needfindDifference,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI区别特征失败", error);
    }
  }
  //AI理解文件
  async quickUnderstandingService(needUnderstanding) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/aiwrite/quickUnderstanding",
        {
          needUnderstanding,
        }
      );
      return response.data.result;
    } catch (error) {
      console.err("AI理解文件失败", error);
    }
  }
}
export default new AiWriteService();
