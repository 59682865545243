import React from "react";
import { ReactTyped } from "react-typed";

const CheckTypeWriterPrompt = () => (
  <ReactTyped
    strings={[
      "由于质检功能检查不准确，重新训练模型中，敬请期待！若要尝鲜，可进入AI辅助撰写页面使用其'AI检查质量'功能！,感谢您的理解与支持！",
    ]}
    typeSpeed={50} // 打字速度
  />
);

export default CheckTypeWriterPrompt;
