import React from "react";
import { ReactTyped } from "react-typed";

const AiNavTypeWriterGuoJi = () => (
  <ReactTyped
    strings={["国外AI大模型"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default AiNavTypeWriterGuoJi;
