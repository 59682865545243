import axios from "axios";
class WriteService {
  async addClaimMarkService(number, needmarkClaim) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/write/addClaimsMark",
        {
          number,
          needmarkClaim,
        }
      );
      if (
        response.data.result.conFusion &&
        response.data.result.conFusion.length > 0
      ) {
        if (
          response.data.result.not_Appear &&
          response.data.result.not_Appear.length > 0
        ) {
          // 输出两个数组
          alert(
            "*注意，以下零部件名称容易混淆，建议修改:\n\n" +
              response.data.result.conFusion.join("\n") +
              "\n\n*注意，以下零部件名称在左侧输入框中并未出现：\n" +
              response.data.result.not_Appear.join("、")
          );
        } else {
          // 仅输出conFusion数组
          alert(
            "*注意，以下零部件名称容易混淆，建议修改:\n\n" +
              response.data.result.conFusion.join("\n")
          );
        }
      } else if (
        response.data.result.not_Appear &&
        response.data.result.not_Appear.length > 0
      ) {
        // 仅输出not_Appear数组
        alert(
          "*注意，以下零部件名称在左侧输入框中并未出现：\n" +
            response.data.result.not_Appear.join("、")
        );
      }
      return response.data.result.result;
    } catch (error) {
      console.error("添加权利要求书附图标记发生错误", error);
      return null;
    }
  }
  async addInstrucMarkService(number, needmarkInstruc) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/write/addInstrucMark",
        {
          number,
          needmarkInstruc,
        }
      );

      if (
        response.data.result.conFusion &&
        response.data.result.conFusion.length > 0
      ) {
        if (
          response.data.result.not_Appear &&
          response.data.result.not_Appear.length > 0
        ) {
          // 输出两个数组
          alert(
            "*注意，以下零部件名称容易混淆，建议修改:\n\n" +
              response.data.result.conFusion.join("\n") +
              "\n\n*注意，以下零部件名称在左侧输入框中并未出现：\n" +
              response.data.result.not_Appear.join("、")
          );
        } else {
          // 仅输出conFusion数组
          alert(
            "*注意，以下零部件名称容易混淆，建议修改:\n\n" +
              response.data.result.conFusion.join("\n")
          );
        }
      } else if (
        response.data.result.not_Appear &&
        response.data.result.not_Appear.length > 0
      ) {
        // 仅输出not_Appear数组
        alert(
          "*注意，以下零部件名称在左侧输入框中并未出现：\n" +
            response.data.result.not_Appear.join("、")
        );
      }
      return response.data.result.result;
    } catch (error) {
      console.error("添加权利要求书附图标记发生错误", error);
      return null;
    }
  }
  async deleteClaimMarkService(needDeletClaim) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/write/deleClaimsMark",
        {
          needDeletClaim,
        }
      );

      return response.data.result;
    } catch (error) {
      console.error("删除权利要求附图标记发生错误", error);
    }
  }
  async deleteInstrucMarkService(needDeletInstruc) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/write/deleInstrucMark",
        {
          needDeletInstruc,
        }
      );
      return response.data.result;
    } catch (error) {
      console.error("删除说明书附图标记发生错误", error);
    }
  }
  async GenerateInventService(needgeneratInventionContent) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/write/generatInventionContent",
        {
          needgeneratInventionContent,
        }
      );
      return response.data.result;
    } catch (error) {
      console.error("生成发明内容发生错误", error);
    }
  }
  async GenerateUseService(needgeneratNewTypeContent) {
    try {
      let response = await axios.post(
        "https://www.techexplore.cn/write/generatNewTypeContent",
        {
          needgeneratNewTypeContent,
        }
      );
      return response.data.result;
    } catch (error) {
      console.error("生成实用新型内容发生错误", error);
    }
  }
}

export default new WriteService();
