import React from "react";
import { ReactTyped } from "react-typed";

const AiWriteTypeWriter = () => (
  <ReactTyped
    strings={["有什么可以帮忙的？"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default AiWriteTypeWriter;
