import React from "react";

// WritePageSection 组件用于渲染页面的浮动导航栏
export const FloatSection = () => {
  return (
    <>
      {/* 浮动导航栏 */}
      <div className="list-group floatatag" style={{ width: "10vw" }}>
        {/* 添加附图标记链接，跳转到添加附图标记部分 */}
        <a
          href="/write#firstcontainer"
          className="list-group-item list-group-item-action link-primary"
          style={{ textAlign: "center" }}
        >
          添加附图标记
        </a>

        {/* 删除附图标记链接，跳转到删除附图标记部分 */}
        <a
          href="/write#secondcontainer"
          className="list-group-item list-group-item-action link-primary"
          style={{ textAlign: "center" }}
        >
          删除附图标记
        </a>

        {/* 生成发明内容链接，跳转到生成发明内容部分 */}
        <a
          href="/write#thirdcontainer"
          className="list-group-item list-group-item-action link-primary"
          style={{ textAlign: "center" }}
        >
          生成发明内容
        </a>
      </div>
    </>
  );
};

export default FloatSection;
