import React from "react";
import { ReactTyped } from "react-typed";

const SearchNavTypeWriterGuoJi = () => (
  <ReactTyped
    strings={["国际检索网站"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default SearchNavTypeWriterGuoJi;
