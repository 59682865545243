import React from "react";
import { ReactTyped } from "react-typed";

const WriteTypeWriterTianjiaFutuBiaoJi = () => (
  <ReactTyped
    strings={["添加附图标记"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default WriteTypeWriterTianjiaFutuBiaoJi;
