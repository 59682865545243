import React from "react";
import SearchNavTypeWriterGuoneiMianfei from "./units/typewriter/searchnav/SearchNavTypeWriterGuoneiMianfei";
import SearchNavTypeWriterGuoneiFufei from "./units/typewriter/searchnav/SearchNavTypeWriterGuoneiFufei";
import SearchNavTypeWriterGuoJi from "./units/typewriter/searchnav/SearchNavTypeWriterGuoJi";

export const Searchnav_component = () => {
  return (
    <main className="searchnav">
      <section className="chinese">
        <h2 className="class_h2 searchHeader">
          <SearchNavTypeWriterGuoneiMianfei />
        </h2>
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <a
                href="https://www.lotut.com/"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                龙图腾
              </a>

              <a
                href="https://www.bayuegua.com/"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                创新大脑
              </a>

              <a
                href="http://search.cnipr.com/"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                中国知识产权网 CNIPR
              </a>

              <a
                href="https://www.patenthub.cn/"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                专利汇
              </a>

              <a
                href="http://www.innojoy.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                大为 Innojoy
              </a>
            </div>
            <div className="col">
              <a
                href="http://www.uyanip.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                度衍
              </a>

              <a
                href="https://www.rainpat.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                润桐
              </a>

              <a
                href="http://epub.cnki.net/KNS/brief/result.aspx?dbPrefix=SCOD"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                中国知网（专利）
              </a>

              <a
                href="http://librarian.wanfangdata.com.cn/default.aspx?dbid=Paper"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                万方数据（专利）
              </a>

              <a
                href="https://www.nstl.gov.cn/search.html"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                国家科技数字图书馆
              </a>
            </div>
            <div className="col">
              <a
                href="http://chinaip.cnipa.gov.cn"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                国家重点产业专利信息服务平台
              </a>

              <a
                href="http://zhuanli.baidu.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                百度专利
              </a>

              <a
                href="http://www.duxiu.com/?channel=searchPatent"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                超星独秀
              </a>

              <a
                href="http://www.duxiu.com/?channel=searchPatent"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                国家知识产权局专利信息公众服务系统
              </a>

              <a
                href="https://pss-system.cponline.cnipa.gov.cn/conventionalSearch"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                国家知识产权局专利检索与分析
              </a>
            </div>
          </div>
        </div>

        <h2 className="class_h2 guoneiFufeiJianSuoHeader">
          <SearchNavTypeWriterGuoneiFufei />
        </h2>

        <div className="container text-center">
          <div className="row">
            <div className="col">
              <a
                href="https://www.zhihuiya.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                智慧芽
              </a>
              <a
                href="http://www.incopat.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                incoPat
              </a>
            </div>
            <div className="col">
              <a
                href="http://baiten.cn"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                佰腾
              </a>
            </div>
            <div className="col">
              <a
                href="http://patyee.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                壹专利
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="international">
        <h2 className="class_h2 guojiFufeiJianSuoHeader">
          <SearchNavTypeWriterGuoJi />
        </h2>
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <a
                href="http://ep.espacenet.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                欧洲专利局
              </a>
              <a
                href="https://euipo.europa.eu"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                欧盟知识产权局
              </a>
              <a
                href="http://www.uspto.gov/patents/process/search/index.jsp"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                美国专利商标局
              </a>
              <a
                href="http://www.drugfuture.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                药物在线
              </a>
              <a
                href="https://www.eapo.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                欧亚专利组织
              </a>
              <a
                href="http://www.aripo.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                非洲知识产权组织
              </a>
              <a
                href="https://patentscope.wipo.int/search/zh/search.jsf"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Patentscope
              </a>
              <a
                href="http://www.kipris.or.kr/enghome/main.jsp"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                韩国 KIPRIS 系统
              </a>
              <a
                href="https://www.j-platpat.inpit.go.jp/web/all/top/BTmTopEnglishPage"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                日本特许厅专利数据库
              </a>
              <a
                href="http://www.thomsoninnovate.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                ThomsonInnovation
              </a>
              <a
                href="https://www.derwentinnovation.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                DerwentInnovation
              </a>
              <a
                href="http://www.questel.orbit.com/index.php/en"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Questel
              </a>
              <a
                href="https://www.lexisnexis.com/totalpatent"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                TotalPatent
              </a>
              <a
                href="http://www.patbase.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Patbase
              </a>
              <a
                href="https://www.inquartik.com/patentcloud"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                PatentCloud
              </a>
              <a
                href="http://www.wipsglobal.com/service/mai/main.wips"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                WIPS
              </a>
              <a
                href="http://www.delphion.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                DELPHION
              </a>
              <a
                href="http://search.proquest.com/professional"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                DIALOG
              </a>
              <a
                href="http://www.freepatentsonline.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                免费专利在线
              </a>
              <a
                href="http://www.google.com/patents"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                谷歌专利
              </a>
              <a
                href="http://www.theiet.org/resources/inspec"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                INSPEC
              </a>
              <a
                href="https://priorart.ip.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                IP.com
              </a>
              <a
                href="https://insight.rpxcorp.com/advanced_search"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                RPX Insight
              </a>
              <a
                href="http://www.jpds.co.jp/english/jpnete.html"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                JP-net
              </a>
              <a
                href="https://www.stn.org/stn"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                STN
              </a>
              <a
                href="https://www.cas.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                美国化学文摘社
              </a>
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Pubmed
              </a>
              <a
                href="http://www.micropat.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                MicroPatent
              </a>
              <a
                href="http://patseer.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Patseer
              </a>
              <a
                href="http://www.patanalyse.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Patanalyse
              </a>
              <a
                href="http://www.lens.org/lens"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Patentlens
              </a>
              <a
                href="http://www.sumobrain.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Sumobrain
              </a>
              <a
                href="http://www.surechem.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                Surechem
              </a>
              <a
                href="http://www.alpto.gov.al"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                阿尔巴尼亚检索系统
              </a>
              <a
                href="http://www.inapi.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                阿尔及利亚检索系统
              </a>
              <a
                href="http://www.aipa.am"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                亚美尼亚检索系统
              </a>
              <a
                href="http://www.sakpatenti.org.ge"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                格鲁吉亚检索系统
              </a>
              <a
                href="http://www.osim.ro"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                罗马尼亚检索系统
              </a>
            </div>
            <div className="col">
              <a
                href="http://www.dpma.de"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                德国检索系统
              </a>
              <a
                href="http://www.obi.gr/obi/Default.aspx?tabid=71"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                希腊检索系统
              </a>
              <a
                href="https://www.rpi.gob.gt"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                危地马拉检索系统
              </a>
              <a
                href="http://www.digepih.webs.com"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                洪都拉斯检索系统
              </a>
              <a
                href="http://www.hipo.gov.hu"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                匈牙利检索系统
              </a>
              <a
                href="http://www.patent.is"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                冰岛检索系统
              </a>
              <a
                href="http://www.ipindia.nic.in"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                印度检索系统
              </a>
              <a
                href="http://www.dgip.go.id/statistik-djhki"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                印尼检索系统
              </a>
              <a
                href="http://www.patentsoffice.ie/en/publications_download.aspx"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                爱尔兰检索系统
              </a>
              <a
                href="http://www.patent.justice.gov.il/MojHeb/RashamHaPtentim"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                以色列检索系统
              </a>
              <a
                href="http://www.uibm.gov.it"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                意大利检索系统
              </a>
              <a
                href="http://www.kazpatent.kz"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                哈萨克斯坦检索系统
              </a>
              <a
                href="http://www.kipi.go.ke/index.php/past-ip-journals"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                肯尼亚检索系统
              </a>
              <a
                href="http://www.gccpo.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                科威特检索系统
              </a>
              <a
                href="http://www.lrpv.gov.lv/lv"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                拉脱维亚检索系统
              </a>
              <a
                href="http://www.vpb.lt"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                立陶宛检索系统
              </a>
              <a
                href="http://www.eco.public.lu"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                卢森堡检索系统
              </a>
              <a
                href="https://iponline.myipo.gov.my/ipo/main/search.cfm"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                马来西亚检索系统
              </a>
              <a
                href="https://secure2.gov.mt/IPO/default.aspx?ct=1"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                马耳他检索系统
              </a>
              <a
                href="http://www.ipom.mn"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                蒙古检索系统
              </a>
              <a
                href="http://www.ompic.org.ma/index_en.htm"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                摩洛哥检索系统
              </a>
              <a
                href="http://www.agentschapnl.nl/en/node/108069"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                荷兰检索系统
              </a>
              <a
                href="http://www.iponz.govt.n"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                新西兰检索系统
              </a>
              <a
                href="http://www.patentstyret.no"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                挪威检索系统
              </a>
              <a
                href="http://www.ipaustralia.gov.au"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                澳大利亚检索系统
              </a>
              <a
                href="http://www.patentamt.at"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                奥地利检索系统
              </a>
              <a
                href="http://www.azstand.gov.az"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                阿塞拜疆检索系统
              </a>
              <a
                href="http://www.moic.gov.bh/moic/en"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                巴林检索系统
              </a>
              <a
                href="http://www.belgospatent.org.by"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                白俄罗斯检索系统
              </a>
              <a
                href="http://economie.fgov.be/opri-die.jsp"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                比利时检索系统
              </a>
              <a
                href="http://www.belipo.bz"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                伯利兹检索系统
              </a>
              <a
                href="http://www.mocioman.gov.om"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                阿曼检索系统
              </a>
              <a
                href="http://www.ipo.gov.pk"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                巴基斯坦检索系统
              </a>
              <a
                href="http://www.ipophil.gov.ph/index.php"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                菲律宾检索系统
              </a>
              <a
                href="http://www.uprp.pl/strona-glowna/Menu01"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                波兰检索系统
              </a>
              <a
                href="http://www.marcasepatentesNaN/index.php?section=80"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                葡萄牙检索系统
              </a>
              <a
                href="http://www.gccpo.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                卡塔尔检索系统
              </a>
              <a
                href="http://agepi.gov.md/md"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                摩尔多瓦共和国检索系统
              </a>
            </div>
            <div className="col">
              <a
                href="http://www.rupto.ru/en_site/index_en.htm"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                俄罗斯联邦检索系统
              </a>
              <a
                href="http://org.rdb.rw"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                卢旺达检索系统
              </a>
              <a
                href="http://www.gccpo.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                沙特阿拉伯检索系统
              </a>
              <a
                href="http://www.prv.se"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                瑞典检索系统
              </a>
              <a
                href="https://www.ige.ch"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                瑞士检索系统
              </a>
              <a
                href="http://www.spo.gov.sy/en"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                阿拉伯叙利亚共和国检索系统
              </a>
              <a
                href="http://www.inpi.gov.br"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                巴西检索系统
              </a>
              <a
                href="http://www.cipo.ic.gc.ca"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                加拿大检索系统
              </a>
              <a
                href="http://www.inapi.cl"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                智利检索系统
              </a>
              <a
                href="http://www.sic.gov.co/es/banco-patentes"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                哥伦比亚检索系统
              </a>
              <a
                href="http://www.dziv.hr"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                克罗地亚检索系统
              </a>
              <a
                href="http://www.ocpi.cu"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                古巴检索系统
              </a>
              <a
                href="http://www.upv.cz"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                捷克共和国检索系统
              </a>
              <a
                href="http://www.dkpto.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                丹麦检索系统
              </a>
              <a
                href="http://www.egypo.gov.eg/Search.aspx"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                埃及检索系统
              </a>
              <a
                href="http://www.epa.ee"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                爱沙尼亚检索系统
              </a>
              <a
                href="http://www.inpi.fr"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                法国检索系统
              </a>
              <a
                href="http://www.ipthailand.go.th/ipthailand/index.php?lang=en"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                泰国检索系统
              </a>
              <a
                href="http://www.ippo.gov.mk"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                前南斯拉夫的马其顿共和国检索系统
              </a>
              <a
                href="http://www.innorpi.tn"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                突尼斯检索系统
              </a>
              <a
                href="http://www.turkpatent.gov.tr"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                土耳其检索系统
              </a>
              <a
                href="http://www.sips.gov.ua/en/index.html"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                乌克兰检索系统
              </a>
              <a
                href="http://www.gccpo.org"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                阿拉伯联合酋长国检索系统
              </a>
              <a
                href="http://www.ipo.gov.uk"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                英国检索系统
              </a>
              <a
                href="http://www.noip.gov.vn"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                越南检索系统
              </a>
              <a
                href="http://www.ipos.gov.sg"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                新加坡检索系统
              </a>
              <a
                href="http://www.upv.sk"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                斯洛伐克检索系统
              </a>
              <a
                href="http://www.uil-cnipa.si"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                斯洛文尼亚检索系统
              </a>
              <a
                href="http://www.cipc.co.za"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                南非检索系统
              </a>
              <a
                href="http://www.oepm.es"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                西班牙检索系统
              </a>
              <a
                href="https://www.prh.fi"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                荷兰专利局
              </a>
              <a
                href="https://www.boip.int"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                比荷卢知识产权组织(BOIP)
              </a>
              <a
                href="https://www.gob.mx"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                墨西哥
              </a>
              <a
                href="http://www.miem.gub.uy"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                乌拉圭
              </a>
              <a
                href="http://ima.uz"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                乌兹别克
              </a>
              <a
                href="http://www.yipo.gov.ye"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                也门专利局
              </a>
              <a
                href="http://www.ipo.gov.tt"
                className="list-group-item list-group-item-action list-group-item-light"
              >
                特立尼达和多马哥专利局
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Searchnav_component;
