import React from "react";
import { ReactTyped } from "react-typed";

const AiNavTypeWriterAiPainter = () => (
  <ReactTyped
    strings={["国内ChatGPT镜像"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default AiNavTypeWriterAiPainter;
