import React, { useState } from "react";
import WriteTypeWriterDeleteFutuBiaoJi from "../../typewriter/write/WriteTypeWriterDeleteFutuBiaoJi";
import write_service from "../../../../services/write_service";
// 删除权利要求附图标记
const DeleteMarkSection = () => {
  // 定义状态变量
  const [DeleteClaimsMarkBeforeData, setDeleteClaimsMarkBeforeData] =
    useState(""); // 删除前数据
  const [DeleteClaimsMarkAfterData, setDeleteClaimsMarkAfterData] =
    useState(""); // 删除后数据
  // 获取输入框中的值
  const handDeleteClaimsMarkBeforeData = (e) => {
    setDeleteClaimsMarkBeforeData(e.target.value);
  };
  // 点击按钮
  const handleDeleClaimMark = async () => {
    // 将值发给后端，后端接收数据，改变状态变量的值
    try {
      const response = await write_service.deleteClaimMarkService(
        DeleteClaimsMarkBeforeData
      );
      setDeleteClaimsMarkAfterData(response);
    } catch (error) {
      console.error("删除说明书附图标记出错", error);
    }
  };
  // 删除说明书附图标记
  // 点击按钮
  const handleDeleInstrucMark = async () => {
    // 将值发给后端，后端接收数据，改变状态变量的值
    try {
      const response = await write_service.deleteInstrucMarkService(
        DeleteClaimsMarkBeforeData
      );
      setDeleteClaimsMarkAfterData(response);
    } catch (error) {
      console.error("删除说明书附图标记出错", error);
    }
  };

  return (
    <div>
      {" "}
      <section id="secondcontainer">
        <div className="container text-center functiondiv">
          <form action="">
            <h2 className="layout he221 deleteMarkHeader">
              <WriteTypeWriterDeleteFutuBiaoJi />
            </h2>
            <div className="row">
              <div className="col-md-1" />
              {/* 删除前输入框 */}
              <div className="col-md-4">
                <h3>删除前</h3>
                <textarea
                  className="parts-before deleteMarkBefore"
                  rows={12}
                  cols={60}
                  placeholder="请在此处输入权利要求书或说明书等内容"
                  onChange={handDeleteClaimsMarkBeforeData}
                />
              </div>
              {/* 按钮 */}
              <div className="col-md-2">
                <button
                  onClick={handleDeleClaimMark}
                  type="button"
                  className="btn btn-primary btncenter"
                >
                  权利要求书
                </button>
                <button
                  onClick={handleDeleInstrucMark}
                  type="button"
                  className="btn btn-primary btncenter"
                >
                  说明书
                </button>
              </div>
              {/* 删除后输出框 */}
              <div className="col-md-4">
                <h3>删除后</h3>
                <textarea
                  className="parts-after deleteMarkAfter"
                  rows={12}
                  cols={60}
                  placeholder="点击按钮，即可删除权利要求书或说明书的附图标记，注意：删除说明书只能删除说明书中的数字"
                  value={DeleteClaimsMarkAfterData}
                  readOnly
                />
              </div>
              <div className="col-md-1" />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
export default DeleteMarkSection;
