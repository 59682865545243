import React, { useState } from "react";
import CheckTypeWriterTitle from "./units/typewriter/check/CheckTypeWriterTitle";
import CheckTypeWriterPrompt from "./units/typewriter/check/CheckTypeWriterPrompt";
import CheckService from "../services/check_service";

const Check_component = () => {
  // 状态：用于存储输入框中的内容
  const [needCheckData, setNeedCheckData] = useState("");
  // 状态：用于存储AI检查返回的HTML内容
  const [outPutData, setOutPutData] = useState("");

  // 处理输入框内容变化，更新状态
  const handleNeedCheckData = (e) => {
    setNeedCheckData(e.target.innerText); // 获取输入框内的文本内容
  };

  // 高亮敏感字符
  const handleHightMingtChar = async () => {
    try {
      // 调用CheckService的AI检查服务，传入需要检查的数据
      let response = await CheckService.HeightLightCharService(needCheckData);
      setOutPutData(response); // 将返回的HTML内容设置到状态中
    } catch (error) {
      console.error("高亮敏感字符", error); // 处理错误
    }
  };
  // 高亮所述
  const handleHightLighMingGanChar = async () => {
    try {
      // 调用CheckService的AI检查服务，传入需要检查的数据
      let response = await CheckService.HeightLightSuoShuService(needCheckData);
      setOutPutData(response); // 将返回的HTML内容设置到状态中
    } catch (error) {
      console.error("高亮敏感字符", error); // 处理错误
    }
  };
  //取消高亮
  const handleNoHightLigh = async () => {
    try {
      // 调用CheckService的AI检查服务，传入需要检查的数据
      let response = await CheckService.NohighlightService(needCheckData);
      setOutPutData(response); // 将返回的HTML内容设置到状态中
    } catch (error) {
      console.error("高亮敏感字符", error); // 处理错误
    }
  };

  return (
    <>
      <main>
        <section className="finder">
          <h2 className="checkHeader" id="checkapply">
            <CheckTypeWriterTitle />
          </h2>
          <p className="updatemsg">
            <CheckTypeWriterPrompt />
          </p>
          {/* 输入框容器：用户可以在此输入需要检查的文本 */}
          <div className="topdiv container">
            <div
              onInput={handleNeedCheckData}
              data-placeholder="请输入权利要求书，或，说明书文字部分（用于检查错别字及错误标点符号等敏感词）"
              id="needcheck"
              contentEditable="true"
              dangerouslySetInnerHTML={{ __html: outPutData }} // 将AI返回的HTML内容渲染到此处
            />
          </div>
          {/* 输出结果容器：显示AI检查后的内容 */}
          <div className="bottomdiv">
            {/* 错误反馈区域：展示AI检查结果 */}

            {/* 控制按钮区域：提供不同的操作按钮 */}
            <div className="sondivbottom container">
              <div className="row">
                <div className="col btndiv container">
                  <button
                    onClick={handleHightMingtChar}
                    type="button"
                    className="checkbtn btn btn-primary heighLightMinganZifuBtn"
                  >
                    高亮敏感字符
                  </button>
                </div>
                <div className="col btndiv container">
                  <button
                    onClick={handleHightLighMingGanChar}
                    type="button"
                    className="checkbtn btn btn-primary heighLightSuoShubtn"
                  >
                    高亮 &nbsp;"所述"
                  </button>
                </div>
                <div className="col btndiv container">
                  <button
                    onClick={handleNoHightLigh}
                    type="button"
                    className="checkbtn btn btn-secondary quxiaoGaoLiangBtn"
                  >
                    取消高亮
                  </button>
                </div>
                <div className="col btndiv container">
                  <button
                    onClick={() =>
                      alert(
                        `如不会使用或使用上存在错误，可关注本站小红书账号389715010反馈问题`
                      )
                    }
                    type="button"
                    className="btn btn-secondary errorbtn"
                  >
                    错误反馈
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Check_component;
