import React from "react";

export const Home_component = () => {
  return (
    <>
      <main>
        <section className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light gradient">
          <div className="col-md-5 p-lg-5 mx-auto my-5">
            <h1 className="display-4 fw-normal">免费、高效、准确</h1>
            <p className="lead fw-normal">
              专利代理师必备助手，提高您的工作效率。
            </p>
            <a className="btn btn-outline-secondary" href="/write">
              现在开始
            </a>
          </div>
        </section>
        <section className="functionblock">
          {/* 第一行 */}
          <div class="container text-center">
            <div class="row">
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/addmark_img.svg"
                      alt="添加附图标号"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">添加附图标号</h3>
                    <p>添加附图标号，标号更高效！</p>
                    <a href="/write#firstcontainer" className="btn btn-primary">
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/deletemark_img.svg"
                      alt="删除附图标号"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">删除附图标号</h3>
                    <p>删除附图标号，改稿更高效！</p>
                    <a
                      href="/write#secondcontainer"
                      className="btn btn-primary"
                    >
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/generate_img.svg"
                      alt="生成发明内容"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">生成发明内容</h3>
                    <p>生成发明内容，复制更高效！</p>
                    <a href="/write#thirdcontainer" className="btn btn-primary">
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 第二行 */}
          <div class="container text-center">
            <div class="row">
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/check_img.svg"
                      alt="权要自检"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">检查敏感字符</h3>
                    <p>检查敏感字符，自检更高效！</p>
                    <a
                      href="/check#inspection-core"
                      className="btn btn-primary"
                    >
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/arrange_img.svg"
                      alt="AI对话聊天机器人"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">AI智能对话</h3>
                    <p>AI智能对话，理解更高效！</p>
                    <a href="/aiwrite" className="btn btn-primary">
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/fly_img.svg"
                      alt="撰写技术交底书"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">撰写技术交底</h3>
                    <p style={{ textalign: "left" }}>
                      撰写技术交底，提案更高效！
                    </p>
                    <a
                      href="/aiwrite#idtechnicalDisclosureLetter"
                      className="btn btn-primary"
                    >
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 第三行 */}
          <div class="container text-center">
            <div class="row">
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/background_img.svg"
                      alt="撰写技术效果"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">撰写技术效果</h3>
                    <p>撰写技术效果，撰写更高效！</p>
                    <a
                      href="/aiwrite#idwritingTechnicalEffects"
                      className="btn btn-primary"
                    >
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/help_img.svg"
                      alt="撰写背景技术"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">撰写背景技术</h3>
                    <p>撰写背景技术，撰写更高效！</p>
                    <a
                      href="/aiwrite#idwritingBackground"
                      className="btn btn-primary"
                    >
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
              <div class="col">
                {" "}
                <div className="col d-flex align-items-start">
                  <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <img
                      src="/images/layout/compare_img.svg"
                      alt="快速理解对比文件"
                      width="40vw"
                      height="40vh"
                    />
                  </div>
                  <div>
                    <h3 className="fs-2">理解对比文件</h3>
                    <p>理解对比文件，答复更高效！</p>
                    <a
                      href="/aiwrite#idquickUnderstanding"
                      className="btn btn-primary"
                    >
                      点击进入
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Home_component;
