import React from "react";
import { ReactTyped } from "react-typed";

const SearchNavTypeWriterGuoneiMianfei = () => (
  <ReactTyped
    strings={["国内免费检索网站"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default SearchNavTypeWriterGuoneiMianfei;
