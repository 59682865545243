import React from "react";
import { ReactTyped } from "react-typed";

const WriteTypeWriterShengchengFaMingNeirong = () => (
  <ReactTyped
    strings={["生成发明内容"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default WriteTypeWriterShengchengFaMingNeirong;
