import React, { useState } from "react";
import WriteTypeWriterShengchengFaMingNeirong from "../../typewriter/write/WriteTypeWriterShengchengFaMingNeirong";
import WriteService from "../../../../services/write_service";

const GeneratedContent = () => {
  // 状态变量：用于保存输入框的值
  const [generatBefore, setgeneratBefore] = useState(); // 输入前内容
  const [generatAfter, setgeneratAfter] = useState(); // 输出后的内容

  // 处理输入框内容变化的函数
  const handleGetData = (e) => {
    setgeneratBefore(e.target.value); // 更新输入框的值
  };

  // 处理点击“发明”按钮后的函数
  const handleGenerateInvent = async () => {
    try {
      // 向后端发送请求，生成发明内容
      let response = await WriteService.GenerateInventService(generatBefore);
      setgeneratAfter(response); // 设置输出框的内容为后端返回的发明内容
    } catch (e) {
      console.error("生成发明内容错误", e); // 错误处理
    }
  };

  // 处理点击“实用新型”按钮后的函数
  const handleGenerateUse = async () => {
    try {
      // 向后端发送请求，生成实用新型内容
      let response = await WriteService.GenerateUseService(generatBefore);
      setgeneratAfter(response); // 设置输出框的内容为后端返回的实用新型内容
    } catch (e) {
      console.error("生成实用新型内容错误", e); // 错误处理
    }
  };

  return (
    <section id="thirdcontainer">
      <div className="container text-center functiondiv">
        <form action="">
          <h2 className="layout addInventHeader">
            <WriteTypeWriterShengchengFaMingNeirong />
          </h2>
          <div className="row">
            <div className="col-md-1" />
            {/* 输入框：用户输入的内容 */}
            <div className="col-md-4">
              <h3>输入框</h3>
              <textarea
                className="parts-before generatBefore"
                rows={12}
                cols={60}
                placeholder="请在此处输入权利要求书"
                onChange={handleGetData} // 当输入框内容变化时，更新状态
              />
            </div>
            {/* 按钮：用于触发生成发明或实用新型的操作 */}
            <div className="col-md-2">
              <button
                onClick={handleGenerateInvent} // 点击发明按钮时，调用handleGenerateInvent函数
                type="button"
                className="btn btn-primary btncenter"
              >
                发明
              </button>
              <button
                onClick={handleGenerateUse} // 点击实用新型按钮时，调用handleGenerateUse函数
                type="button"
                className="btn btn-primary btncenter"
              >
                实用新型
              </button>
            </div>
            {/* 输出框：显示生成的内容 */}
            <div className="col-md-4">
              <h3>输出框</h3>
              <textarea
                className="parts-after generatAfter"
                rows={12}
                cols={60}
                placeholder="点击按钮，即可生成发明内容或实用新型内容"
                value={generatAfter} // 输出框内容绑定状态变量generatAfter
              />
            </div>
            <div className="col-md-1" />
          </div>
        </form>
      </div>
    </section>
  );
};

export default GeneratedContent;
