import React from "react";
import { ReactTyped } from "react-typed";
const AiNavNavTypeWriterGuonei = () => (
  <ReactTyped
    strings={["国内AI大模型"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);
export default AiNavNavTypeWriterGuonei;
