import React from "react";
import { ReactTyped } from "react-typed";

const CheckTypeWriterTitle = () => (
  <ReactTyped
    strings={["检查权利要求书及说明书中的敏感词"]}
    typeSpeed={50}
    backSpeed={50}
    backDelay={1000}
  />
);

export default CheckTypeWriterTitle;
